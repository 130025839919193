<template>
  <b-carousel
    class="bg-student-onboarding is-size-6-mobile"
    ref="bgcarouselwrapper"
    :autoplay="false"
    :arrow-hover="false"
    :repeat="false"
    :arrow="true"
    icon-size="is-large"
    :progress="true"
    progress-type="is-primary"
    v-model="slideRank"
  >
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">
            Welcome {{ faci.playerName }}!
          </h1>
          <article
            class="message"
            v-if="!faci.custoOnboarding || !faci.custoOnboarding.length"
          >
            <div class="message-body has-text-grey-darker">
              This game will teach you key supply-chain concepts, by simulating
              a
              {{ currentGame.params.product }} distribution network.
              <br />
              You'll play as
              <b>{{ faci.name }}</b
              >.
            </div>
          </article>
          <article
            class="message"
            v-if="faci.custoOnboarding && faci.custoOnboarding.length"
          >
            <div
              class="message-body has-text-grey-darker"
              style="white-space:pre-wrap"
            >
              {{ faci.custoOnboarding }}
            </div>
          </article>
          <br class="is-hidden-mobile" />

          <div class="columns is-vcentered is-centered">
            <div
              class="column has-text-centered is-narrow has-text-grey is-hidden-mobile"
            >
              <b-icon size="is-medium" icon="account"></b-icon>
              <br />
              Consumer
            </div>
            <div
              class="column game-info has-text-centered columns is-mobile is-vcentered mx-0"
              :key="faciRank.id"
              v-for="faciRank in currentFacilities"
            >
              <div class="column is-hidden-mobile pr-5">
                <faci-arrow :is-grey="true" :only-left="false"></faci-arrow>
              </div>
              <div
                class="column is-paddingless has-text-centered"
                :class="{
                  'has-text-success': faciRank.id === faci.id,
                  'is-size-5': faciRank.id === faci.id,
                  'is-hidden-mobile': faciRank.id !== faci.id
                }"
              >
                <b-icon size="is-medium" :icon="faciRank.icon"></b-icon>
                <br />
                {{ faciRank.name | capitalize({ onlyFirstLetter: true }) }}
              </div>
            </div>
          </div>
          You'll sell units to the
          <b>{{ myPartners.client }}</b> (your client).
          <br />
          <span v-if="myPartners.supplier">
            You'll purchase units from the
            <b>{{ myPartners.supplier }}</b> (your supplier).
          </span>
          <span v-if="!myPartners.supplier">
            You'll produce your own units.
          </span>
          <br />
          The game is played in several turns, representing
          <b-icon size="is-small" icon="flag"></b-icon>
          <b>{{ currentGame.params.timeUnit || "week" }}s</b>.
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">Objective of the game</h1>
          <span class="is-size-5 has-text-success"
            ><b>Fullfill</b> your client's orders while keeping your
            <b>costs</b> to a minimum.</span
          >
          <br />
          <br />
          Your operating <b>Costs</b> will add up every
          {{ currentGame.params.timeUnit || "week" }} based on the number of
          units<br />
          you have in <b>Stock</b>
          <span v-if="faci.allowBacklog">
            or <b>Backorder</b> (= late orders waiting to be fulfilled)</span
          ><span v-if="!faci.allowBacklog">
            or if you <b>Miss sales</b> (if you don't have enough stock to meet
            the demand)</span
          >.
          <br />
          <br />
          <b-icon icon="triangle" size="is-small"></b-icon>
          Stock cost :
          <b class="has-text-danger">{{
            faci.stockCost | currency(currentGame.params.currency, 2)
          }}</b>
          <em>/unit/{{ currentGame.params.timeUnit || "week" }}</em>
          <br />
          <span v-if="faci.allowBacklog"
            ><b-icon icon="clock" size="is-small"></b-icon>
            Backorder cost :
            <b class="has-text-danger">{{
              faci.backlogCost | currency(currentGame.params.currency, 2)
            }}</b>
            <em
              >/late unit/{{ currentGame.params.timeUnit || "week" }}</em
            ></span
          >
          <span v-if="!faci.allowBacklog"
            ><b-icon icon="close" size="is-small"></b-icon>
            Missed Sale cost :
            <b class="has-text-danger">{{
              faci.missedSaleCost | currency(currentGame.params.currency, 2)
            }}</b>
            <em>/missed sale unit</em></span
          >
          <br />
          <span v-if="faci.unitPrice"
            ><br />
            Your <b>Revenue</b> will increase based on the number of units you
            ship to your client (<b class="has-text-success"
              >+{{
                faci.unitPrice | currency(currentGame.params.currency, 2)
              }}</b
            ><em>/unit sold</em>)<br
          /></span>
          <br />
          <p>
            <em>You are starting with :</em>
          </p>
          <br />
          <div class="columns is-centered">
            <div class="faci-box column">
              <b-icon :icon="faci.icon" size="is-medium"></b-icon>
              <h3 class="is-underlined mb-1">
                {{ faci.name | capitalize({ onlyFirstLetter: true }) }}
              </h3>
              <div class="columns is-mobile is-centered">
                <div class="column is-half">
                  <b-icon icon="triangle" size="is-small"></b-icon> Stock :
                  {{ faci.stock }} units
                </div>
                <div class="column is-half" v-if="faci.allowBacklog">
                  <b-icon icon="clock" size="is-small"></b-icon> Backorder :
                  {{ faci.backlog }} units
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">
            What happens each {{ currentGame.params.timeUnit || "week" }} ?
          </h1>
          <ul>
            <li>
              <b-icon icon="email" size="is-small"></b-icon> You'll receive an
              order from your client <em>(the {{ myPartners.client }})</em> =
              <b class="has-text-primary">Incoming Demand</b>,
            </li>
            <li>
              <b-icon
                :icon="myPartners.supplier ? 'truck' : 'wrench'"
                size="is-small"
              ></b-icon>
              You'll receive one of your previous
              {{ myPartners.supplier ? "purchase" : "production" }} orders =
              <b class="has-text-success"
                >Incoming
                {{ myPartners.supplier ? "Receipt" : "Production" }}</b
              >,
            </li>
            <li>
              <b-icon icon="check" size="is-small"></b-icon>
              You'll ship available units towards your client
              <em>(the {{ myPartners.client }})</em> =
              <b class="has-text-info">Outgoing Shipment</b>
            </li>
          </ul>
          <br />
          <span v-if="faci.allowBacklog">
            If you don't have enough stock to fulfill
            <b class="has-text-primary">demand</b>, missing quantities will be
            added to your
            <b><b-icon icon="clock" size="is-small"></b-icon> Backorders</b
            >.<br />They will be <b class="has-text-info">shipped</b> to your
            client as soon as you receive more stock.
          </span>
          <span v-if="!faci.allowBacklog">
            If you don't have enough stock to fulfill
            <b class="has-text-primary">demand</b>, you will
            <b class="has-text-info">ship</b> the maximum<br />until your stock
            reaches 0. The <b>missing quantities</b> will be lost and can't be
            fulfilled later-on.
          </span>

          <br /><br />
          <b>New Stock</b> = <em>Previous Stock</em> +
          <b class="has-text-success">{{
            myPartners.supplier ? "Incoming Receipt" : "Production"
          }}</b>
          -
          <b class="has-text-info">Outgoing Shipment</b>
          <br />
          <br />
          <p>
            <em>For the week 0 :</em>
          </p>
          <br />
          <div class="columns is-centered">
            <div class="faci-box column columns is-vcentered">
              <div class="column px-0">
                <div>
                  <b-icon icon="email" size="is-small"></b-icon>
                  Inc. Demand :
                  <b class="has-text-primary">-{{ faci.startOrders }}</b>
                </div>
                <svg class="svg-wrap-horizontal" width="100%" height="20px">
                  <svg viewBox="0 0 1 2" preserveAspectRatio="xMaxYMid">
                    <polyline
                      fill="#fffce8"
                      stroke-width="0.1"
                      stroke="grey"
                      points="0,0 1,1 0,2 "
                    />
                  </svg>
                  <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                    <rect x="0" y="0.475" width="1" height="0.05" fill="grey" />
                  </svg>
                </svg>
                <svg
                  class="svg-wrap-horizontal mt-5"
                  width="100%"
                  height="20px"
                >
                  <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
                    <polyline
                      fill="#fffce8"
                      stroke-width="0.1"
                      stroke="grey"
                      points="1,0 0,1 1,2 "
                    />
                  </svg>
                  <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                    <rect x="0" y="0.475" width="1" height="0.05" fill="grey" />
                  </svg>
                </svg>
                <div>
                  <b-icon
                    icon="truck"
                    size="is-small"
                    custom-class="mdi-flip-h"
                  ></b-icon>
                  Out. Shipment :
                  <b class="has-text-info">{{ faci.startOrders }}</b>
                </div>
              </div>
              <div class="column is-3">
                <b-icon :icon="faci.icon" size="is-medium"></b-icon>
                <h3>{{ faci.name | capitalize({ onlyFirstLetter: true }) }}</h3>
                <div class="columns is-multiline mt-2"></div>
              </div>
              <div class="column px-0">
                <div style="opacity:0.5">
                  <b-icon icon="email" size="is-small"></b-icon>
                  Order :
                  <b class="has-text-dark">{{ faci.startOrders }}</b>
                </div>
                <svg class="svg-wrap-horizontal" width="100%" height="20px">
                  <svg viewBox="0 0 1 2" preserveAspectRatio="xMaxYMid">
                    <polyline
                      fill="#fffce8"
                      stroke-width="0.1"
                      stroke="lightgrey"
                      points="0,0 1,1 0,2 "
                    />
                  </svg>
                  <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                    <rect
                      x="0"
                      y="0.475"
                      width="1"
                      height="0.05"
                      fill="lightgrey"
                    />
                  </svg>
                </svg>
                <svg
                  class="svg-wrap-horizontal mt-5"
                  width="100%"
                  height="20px"
                >
                  <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
                    <polyline
                      fill="#fffce8"
                      stroke-width="0.1"
                      stroke="grey"
                      points="1,0 0,1 1,2 "
                    />
                  </svg>
                  <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                    <rect x="0" y="0.475" width="1" height="0.05" fill="grey" />
                  </svg>
                </svg>
                <div>
                  <b-icon
                    :icon="myPartners.supplier ? 'truck' : 'wrench'"
                    size="is-small"
                    custom-class="mdi-flip-h"
                  ></b-icon>
                  {{ myPartners.supplier ? "Inc. Receipt" : "Inc. Prod" }}
                  :
                  <b class="has-text-success">+{{ faci.startOrders }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">
            Send an order
          </h1>
          Each {{ currentGame.params.timeUnit || "week" }} you have to decide
          <b>how many units</b> to order
          {{
            myPartners.supplier
              ? `from your supplier (the ${myPartners.supplier})`
              : "for production"
          }}. <br /><b>Think carefully</b> before ordering, try to anticipate
          demand and monitor your stock level.<br /><br />
          <div class="columns is-centered">
            <div class="faci-box column">
              <b-icon :icon="faci.icon" size="is-medium"></b-icon>
              <h3>{{ faci.name | capitalize({ onlyFirstLetter: true }) }}</h3>
              <div class="columns mt-1 is-mobile">
                <div class="column is-offset-one-quarter">
                  <div class="py-2 has-background-light">
                    xxx
                  </div>
                </div>
                <div class="column has-text-left">
                  <b-button type="is-success" :disabled="true">
                    Order
                  </b-button>
                </div>
              </div>
              <ul>
                <li>
                  <b-icon
                    :icon="myPartners.supplier ? 'truck' : 'wrench'"
                    size="is-small"
                  ></b-icon>
                  +{{ faci.startOrders }} pcs in 1
                  {{ currentGame.params.timeUnit || "week" }}
                </li>
                <li>
                  <b-icon
                    :icon="myPartners.supplier ? 'truck' : 'wrench'"
                    size="is-small"
                  ></b-icon>
                  +{{ faci.startOrders }} pcs in 2
                  {{ currentGame.params.timeUnit || "week" }}s
                </li>
                <li class="is-italic has-text-grey">To be decided</li>
              </ul>
            </div>
          </div>
          Please note that each order will only arrive in stock after
          <b>{{ faci.leadtime }} {{ currentGame.params.timeUnit || "week" }}s</b
          ><span v-if="faci.leadtime > 1 && myPartners.supplier"
            >:<br /><b>1 {{ currentGame.params.timeUnit || "week" }}</b> for the
            order to arrive to your supplier and
            <b>
              {{ faci.leadtime - 1 }}
              {{ currentGame.params.timeUnit || "week" }}s
            </b>
            for the delivery to you</span
          >.
          <br />
          <span v-if="faci.leadtime > 1"
            >The game will already start with incoming quantities:
            {{ faci.startOrders }}pcs/{{
              currentGame.params.timeUnit || "week"
            }}
            for the next
            {{ faci.leadtime > 2 ? faci.leadtime - 1 : "" }}
            {{ currentGame.params.timeUnit || "week"
            }}{{ faci.leadtime > 2 ? "s" : "" }}.</span
          >
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white pb-6">
        <div class="hero-body has-text-centered mb-2 pb-0">
          <h1 class="title is-size-4-mobile mb-3">
            Go to the next {{ currentGame.params.timeUnit || "week" }}
          </h1>
          <p>
            Please wait before all the participants to this game are connected
            before you start sending orders.
            <br />
            <b
              >Once all the connected players have validated their order, the
              game moves to the next
              {{ currentGame.params.timeUnit || "week" }} automatically</b
            >.
            <br />
            If no one is connected to some of the roles, the computer will
            automatically order for them.
          </p>

          <br />
          <em>Currently connected :</em><br />
          <ul class="mt-2">
            <li
              v-for="faciRank in currentFacilities"
              :key="faciRank.id"
              :class="{ 'has-text-success': faciRank.user }"
            >
              <b-icon :icon="faciRank.icon" size="is-small"></b-icon>
              {{ faciRank.name | capitalize({ onlyFirstLetter: true }) }} :
              <b-icon
                :icon="faciRank.user ? 'account' : 'laptop'"
                size="is-small"
              ></b-icon>
              <b v-if="faciRank.user">{{
                faciRank.playerName ? faciRank.playerName : "anonymous user"
              }}</b>
              <b v-if="!faciRank.user"> Computer</b>
            </li>
          </ul>
          <br />
          The game will stop after an <em>unknown</em> number of
          {{ currentGame.params.timeUnit || "week" }}s.
          <br />
          We will then look at the evolution of your data.
          <br /><br />
          <b class="has-text-success is-size-5"
            >The supply-chain with the lowest cost
            <span v-if="faci.unitPrice">and highest revenue</span> wins !</b
          >
          <br />
        </div>
        <div class="buttons is-centered mt-2 are-large">
          <b-button
            rounded
            type="is-primary"
            class="btn-play-onboard-next"
            @click="$store.commit('setShowOnboarding', false)"
            ref="nextbtn"
            :style="{
              'background-color': custoLook.btnBestColor,
              color: 'white'
            }"
          >
            Start
          </b-button>
        </div>
      </section>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
import { mapGetters } from "vuex";
import FaciArrow from "@/components/bg/Facility/FaciArrow.vue";

export default {
  name: "BgStudentOnboarding",
  components: { FaciArrow },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentGame",
      "currentFacilities",
      "custoLook"
    ]),
    myPartners() {
      let obj = { supplier: false, client: "final consumer" };
      if (this.currentUser.playingGame) {
        let supplierId = this.currentGame.facilities[
          this.currentUser.playingGame.roleId
        ].supplier_id;
        let clientId = this.currentGame.facilities[
          this.currentUser.playingGame.roleId
        ].client_id;
        if (supplierId) {
          obj.supplier = this.currentGame.facilities[supplierId].name;
        }
        if (clientId) {
          obj.client = this.currentGame.facilities[clientId].name;
        }
      }
      return obj;
    },
    faci() {
      if (this.currentUser.playingGame) {
        return this.currentGame.facilities[this.currentUser.playingGame.roleId];
      }
      return this.currentGame.facilities["1"];
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.nextbtn && this.$refs.nextbtn.$el) {
        this.$refs.nextbtn.$el.focus();
      }
      if (this.$refs.bgcarouselwrapper) {
        this.$refs.bgcarouselwrapper.$el.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 2);
  },
  data() {
    return {
      isVisible: true,
      slideRank: 0,
      nbSlides: 5,
      custoContent: {
        "1": "Hello !",
        "200": "Hi",
        "300": "Hey",
        "400": "Whattup"
      }
    };
  }
};
</script>

<style lang="scss">
.bg-student-onboarding {
  .hero-body {
    padding-top: 2em !important;
    padding-bottom: 4em !important;
    &.pb-0 {
      padding-bottom: 0 !important;
    }
    @media (max-width: 767px) {
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }
  }
  ul {
    list-style: bullet inside;
  }
  .faci-box {
    background-color: #fffce8;
    border-radius: 4px;
    border: solid 1px rgb(233, 233, 233);
    max-width: 550px;
    padding: 1rem;
    padding-top: 20px;
    margin-bottom: 1em;
  }
}
</style>
