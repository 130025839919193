<template>
  <div class="game-messages-wrapper">
    <section class="box has-background-white px-1-mobile is-relative">
      <button
        class="delete"
        aria-label="close"
        @click="$store.commit('setShowMessages', false)"
      ></button>
      <h1 class="title is-4 has-text-centered mb-2">
        Messages
      </h1>
      <div class="box-divider mb-0"></div>
      <div class="chat-messages pt-2">
        <div
          v-for="(mess, messIndex) in currentMessages"
          :key="`mess-${messIndex}`"
          :class="{ 'has-text-right': myFaci && mess.from === myFaci.id }"
          :id="`mess-${messIndex}`"
          class="mb-3"
        >
          <span v-if="!myFaci || mess.from !== myFaci.id">
            <b :class="{ 'has-text-success': !mess.faciName }"
              >{{
                mess.faciName || "Admin" | capitalize({ onlyFirstLetter: true })
              }}
            </b>
            <em v-if="mess.playerName"
              >({{ mess.playerName | capitalize({ onlyFirstLetter: true }) }})
            </em>
            <span
              >to <b>{{ toMessTabString(mess.to) }}</b></span
            >
            <em v-if="mess.applyWeek"> (week {{ mess.applyWeek }}) </em>
          </span>
          <b v-if="myFaci && mess.from === myFaci.id">
            You
          </b>
          :
          <div>
            <p
              class="chat-bubble"
              :class="{
                'bg-is-admin-mess': !mess.faciName
              }"
            >
              {{ mess.message }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="chat-new"
        v-if="
          (isCreator && !myFaci) ||
            (currentGame.params.configMessages &&
              currentGame.params.configMessages.allowChat &&
              myFaci)
        "
      >
        <div class="box-divider"></div>
        <b-field grouped>
          <b-input
            type="text"
            v-model="newMessageText"
            expanded
            placeholder="Send message"
            @keyup.enter.native="sendMessage"
          >
          </b-input>
          <p class="control">
            <b-button
              icon-right="send"
              type="is-primary"
              @click="sendMessage"
              :disabled="!newMessageText"
              :loading="loadingMess"
            />
          </p>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GameMessages",
  props: {
    isOpen: Boolean
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentMessages",
      "myFaci",
      "currentGame",
      "currentFacilities",
      "isCreator"
    ])
  },
  mounted() {
    this.scrollToEnd();
  },
  methods: {
    scrollToEnd() {
      setTimeout(() => {
        if (this.currentMessages) {
          const content = this.$el.querySelector(".chat-messages");
          content.scrollTop = content.scrollHeight;
        }
      }, 10);
    },
    toMessTabString(toTab) {
      if (
        toTab.length === this.currentFacilities.length ||
        toTab[0] === "all"
      ) {
        return "all";
      }
      return toTab
        .filter(faciId => {
          return this.currentGame.facilities[faciId];
        })
        .map(faciId => {
          if (this.myFaci && faciId === this.myFaci.id) {
            return "you";
          } else {
            return this.currentGame.facilities[faciId].name;
          }
        })
        .join(", ");
    },
    sendMessage() {
      if (this.newMessageText) {
        this.loadingMess = true;
        this.$store
          .dispatch("sendMessage", {
            game_id: this.currentGame.id,
            playerName: this.myFaci ? this.myFaci.playerName : null,
            faciName: this.myFaci ? this.myFaci.name : null,
            from: this.myFaci ? this.myFaci.id : "admin",
            to: this.currentFacilities.map(faci => faci.id),
            message: this.newMessageText
          })
          .then(() => {
            this.loadingMess = false;
            this.newMessageText = "";
          });
      }
    }
  },
  watch: {
    currentMessages(messTabNew, messTabOld) {
      this.scrollToEnd();
      if (
        messTabNew.length &&
        messTabOld.length &&
        messTabNew.length > messTabOld.length
      ) {
        this.$store.commit("setShowMessages", true);
      }
    },
    isOpen(newVal) {
      if (newVal) {
        this.scrollToEnd();
      }
    }
  },
  data() {
    return {
      newMessageText: "",
      loadingMess: false
    };
  }
};
</script>

<style lang="scss">
.game-messages-wrapper {
  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .chat-messages {
    max-height: 300px;
    overflow-y: scroll;
    // border-bottom: solid 1px lightgrey;
    .chat-bubble {
      display: inline-block;
      max-width: 90%;
      padding: 0.5em 0.7em;
      background-color: #eee;
      border-radius: 0.5em;
      width: auto;
    }
    .has-text-right .chat-bubble {
      background-color: #ffc4c2;
    }
    .bg-is-admin-mess.chat-bubble {
      background-color: #acece5;
    }
  }
}
</style>
