<template>
  <div class="bg-recap-box" :class="{ container: $route.name === 'play' }">
    <b-modal :active.sync="isSettingsModalActive" has-modal-card>
      <bg-settings :game="currentGame"></bg-settings>
    </b-modal>
    <b-sidebar
      :open="showMessages && !showNewTurnDetails && !showOnboarding"
      :can-cancel="[]"
      type="is-light"
      :right="isMessageRight"
      v-if="$route.name === 'play'"
    >
      <game-messages
        :is-open="showMessages && !showNewTurnDetails && !showOnboarding"
      ></game-messages>
    </b-sidebar>
    <b-modal
      :active="showOnboarding"
      trap-focus
      @close="$store.commit('setShowOnboarding', false)"
    >
      <bg-student-onboarding></bg-student-onboarding>
    </b-modal>
    <nav class="columns game-info is-vcentered is-mobile is-multiline box">
      <p class="column has-text-centered game-info" v-if="isCreator">
        <b-button
          type="is-warning"
          icon-left="cog"
          @click="isSettingsModalActive = true"
          size="is-small"
        >
          Settings
        </b-button>
      </p>
      <p
        class="column has-text-centered game-info"
        v-if="$route.name === 'play' && myFaci"
      >
        <b-button
          type="is-light"
          icon-left="help-circle"
          size="is-small"
          @click="$store.commit('setShowOnboarding', true)"
        >
          Instructions
        </b-button>
      </p>
      <p
        class="column has-text-centered game-info"
        v-if="
          $route.name === 'play' &&
            (currentMessages.length ||
              (currentGame.params.configMessages &&
                currentGame.params.configMessages.allowChat))
        "
      >
        <b-button
          class="btn-game-messages"
          type="is-light"
          size="is-small"
          icon-left="email"
          @click="$store.commit('setShowMessages', !showMessages)"
        >
          Messages
        </b-button>
      </p>
      <!-- <p
        class="column has-text-centered game-info"
        v-if="$route.name === 'play' && myFaci"
      >
        <b-button
          class="btn-game-newturndetails"
          type="is-light"
          icon-left="flag"
          size="is-small"
          v-if="$route.name === 'play'"
          @click="$store.commit('setShowNewTurnDetails', true)"
        >
          Turn Details
        </b-button>
      </p> -->
      <p
        class="column has-text-centered game-info"
        v-if="$route.name === 'play' && isCreator"
      >
        <b-field>
          <b-select placeholder="Duration" size="is-small" v-model="localTimer">
            <option
              v-for="option in timerList"
              :value="option.duration"
              :key="option.duration"
            >
              {{ option.name }}
            </option>
          </b-select>
          <p class="control">
            <b-button
              class="btn-game-autoplay"
              type="is-info is-light"
              :icon-left="
                currentGame.params.playTimer
                  ? 'timer-off-outline'
                  : 'timer-outline'
              "
              size="is-small"
              @click="toggleAutoPlay"
            >
              {{ currentGame.params.playTimer ? "Stop" : "Start" }} Timer
            </b-button>
          </p>
        </b-field>
      </p>
      <p
        class="column has-text-centered game-info"
        v-if="!currentGame.finished"
      >
        <b-tooltip
          :label="connectedUsersTooltip"
          :active="connectedUsersTooltip ? true : false"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
          ><p>
            <b-icon icon="account" size="is-small"></b-icon>
            <span class="nowrap">
              <span class="is-hidden-mobile">Users</span>
              {{ connectedUsers.length }}/{{ currentFacilities.length }}
            </span>
          </p>
        </b-tooltip>
      </p>
      <p
        class="column has-text-centered game-info"
        v-if="$route.name === 'play' && !currentGame.finished"
      >
        <b-tooltip
          :label="waitingForTooltip"
          type="is-dark"
          position="is-bottom"
          :active="
            waitingForTooltip && ordersNotValidated.length > 0 ? true : false
          "
          :always="
            ordersNotValidated.length > 0 &&
              connectedUsers.length - ordersNotValidated.length > 0
          "
          size="is-small"
          multilined
        >
          <p>
            <b-icon icon="clipboard-text" size="is-small"></b-icon>
            <span class="nowrap">
              <span class="is-hidden-mobile">Orders</span>
              {{ connectedUsers.length - ordersNotValidated.length }}/{{
                connectedUsers.length
              }}
            </span>
          </p>
        </b-tooltip>
      </p>
      <p
        class="column has-text-centered game-info"
        :class="{
          'has-text-danger': currentGame.finished
        }"
      >
        <b-icon icon="flag" size="is-small"></b-icon>
        <br v-if="$route.name !== 'play'" />
        <span class="nowrap">
          <span class="is-hidden-mobile">{{
            currentGame.params.timeUnit || "week" | capitalize
          }}</span>
          {{ currentGame.week }}
        </span>
      </p>
      <p
        class="column has-text-centered game-info"
        v-if="
          $route.name === 'play' &&
            !currentGame.finished &&
            currentGame.params.playTimer
        "
      >
        <timer-clock
          tooltip="Time left to order"
          :success="myFaci && myFaci.validatedOrder"
          :active="currentGame.params.playTimer"
          :timerNextTick="currentGame.params.timerNextTick"
          :show-alerts="myFaci ? true : false"
        ></timer-clock>
      </p>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BgSettings from "@/components/bg/Play/BgSettings.vue";
import GameMessages from "@/components/bg/Play/GameMessages.vue";
import TimerClock from "@/components/Dashboard/TimerClock.vue";
import BgStudentOnboarding from "@/components/bg/Play/BgStudentOnboarding.vue";

export default {
  name: "GameRecapBox",
  components: {
    BgSettings,
    GameMessages,
    TimerClock,
    BgStudentOnboarding
  },
  computed: {
    ...mapGetters([
      "currentFacilities",
      "currentGame",
      "currentUser",
      "connectedUsers",
      "isCreator",
      "showOnboarding",
      "showMessages",
      "currentMessages",
      "showNewTurnDetails",
      "portalLook",
      "myFaci"
    ]),
    isMessageRight() {
      return (
        this.currentFacilities.indexOf(this.myFaci) <
        this.currentFacilities.length / 2
      );
    },
    ordersNotValidated() {
      return this.currentFacilities
        .filter(faci => {
          return !faci.validatedOrder && faci.user;
        })
        .map(faci => faci.name);
    },
    connectedUsersTooltip() {
      if (this.connectedUsers.length == 0) {
        return "No user connected";
      } else if (this.connectedUsers.length == 1) {
        return "The " + this.connectedUsers[0].name + " is connected";
      } else {
        return (
          "The " +
          this.connectedUsers
            .map(faci => {
              return faci.name;
            })
            .slice(0, -1)
            .join(", ") +
          " and " +
          this.connectedUsers
            .map(faci => {
              return faci.name;
            })
            .slice(-1) +
          " are connected"
        );
      }
    },
    waitingForTooltip() {
      if (this.ordersNotValidated.length == 0) {
        return "";
      } else if (this.ordersNotValidated.length == 1) {
        if (
          this.currentUser &&
          this.currentUser.playingGame &&
          this.ordersNotValidated[0] === this.currentUser.playingGame.roleName
        ) {
          return "You are last to order";
        }
        return "Waiting for the " + this.ordersNotValidated[0] + " to order";
      } else {
        return (
          "Waiting for the " +
          this.ordersNotValidated.slice(0, -1).join(", ") +
          " and " +
          this.ordersNotValidated.slice(-1) +
          " to order"
        );
      }
    }
  },
  methods: {
    toggleAutoPlay() {
      if (!this.currentGame.params.playTimer) {
        if (this.localTimer < 30000) {
          this.$store.commit("setAutoShowNewTurnModal", false);
        }
        this.$buefy.toast.open(
          `${
            this.currentGame.players.length > 1 ? "The players" : "You"
          } now have a limited time to order each turn`
        );
        this.$store
          .dispatch("updateGameDoc", [
            {
              gid: this.currentGame.id,
              obj: { [`params.timer`]: this.localTimer }
            }
          ])
          .then(() => {
            this.$store.dispatch("startBgTimer", [{ ...this.currentGame }]);
          });
      } else {
        this.$store.dispatch("updateGameDoc", [
          {
            gid: this.currentGame.id,
            obj: { [`params.playTimer`]: false }
          }
        ]);
      }
    }
  },
  mounted() {
    if (this.currentGame && this.currentGame.params.timer) {
      this.localTimer = this.currentGame.params.timer;
    }
  },
  data() {
    return {
      localTimer: 3000,
      isSettingsModalActive: false,
      isManualModalActive: false,
      showManual: false,
      timerList: [
        { name: "3 sec", duration: 3000 },
        { name: "30 sec", duration: 30000 },
        { name: "1 min", duration: 60000 },
        { name: "1 min 30", duration: 90000 },
        { name: "2 min", duration: 120000 },
        { name: "2 min 30", duration: 150000 }
      ]
    };
  }
};
</script>

<style lang="scss">
.bg-recap-box {
  &.container {
    padding: 0.8em;
    padding-bottom: 0;
  }
  .columns.game-info {
    margin: 0;
    padding: 0.4em;
  }
  .column.game-info {
    margin: 0 0.5em;
    padding: 0.3em;
    .b-tooltip {
      cursor: help;
    }
    .button {
      .icon {
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 2px;
      }
    }
    .icon {
      padding-right: 10px;
    }
  }
}
</style>
