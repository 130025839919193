var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game-messages-wrapper"},[_c('section',{staticClass:"box has-background-white px-1-mobile is-relative"},[_c('button',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":function($event){return _vm.$store.commit('setShowMessages', false)}}}),_c('h1',{staticClass:"title is-4 has-text-centered mb-2"},[_vm._v(" Messages ")]),_c('div',{staticClass:"box-divider mb-0"}),_c('div',{staticClass:"chat-messages pt-2"},_vm._l((_vm.currentMessages),function(mess,messIndex){return _c('div',{key:("mess-" + messIndex),staticClass:"mb-3",class:{ 'has-text-right': _vm.myFaci && mess.from === _vm.myFaci.id },attrs:{"id":("mess-" + messIndex)}},[(!_vm.myFaci || mess.from !== _vm.myFaci.id)?_c('span',[_c('b',{class:{ 'has-text-success': !mess.faciName }},[_vm._v(_vm._s(_vm._f("capitalize")(mess.faciName || "Admin",{ onlyFirstLetter: true }))+" ")]),(mess.playerName)?_c('em',[_vm._v("("+_vm._s(_vm._f("capitalize")(mess.playerName,{ onlyFirstLetter: true }))+") ")]):_vm._e(),_c('span',[_vm._v("to "),_c('b',[_vm._v(_vm._s(_vm.toMessTabString(mess.to)))])]),(mess.applyWeek)?_c('em',[_vm._v(" (week "+_vm._s(mess.applyWeek)+") ")]):_vm._e()]):_vm._e(),(_vm.myFaci && mess.from === _vm.myFaci.id)?_c('b',[_vm._v(" You ")]):_vm._e(),_vm._v(" : "),_c('div',[_c('p',{staticClass:"chat-bubble",class:{
              'bg-is-admin-mess': !mess.faciName
            }},[_vm._v(" "+_vm._s(mess.message)+" ")])])])}),0),(
        (_vm.isCreator && !_vm.myFaci) ||
          (_vm.currentGame.params.configMessages &&
            _vm.currentGame.params.configMessages.allowChat &&
            _vm.myFaci)
      )?_c('div',{staticClass:"chat-new"},[_c('div',{staticClass:"box-divider"}),_c('b-field',{attrs:{"grouped":""}},[_c('b-input',{attrs:{"type":"text","expanded":"","placeholder":"Send message"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage($event)}},model:{value:(_vm.newMessageText),callback:function ($$v) {_vm.newMessageText=$$v},expression:"newMessageText"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"icon-right":"send","type":"is-primary","disabled":!_vm.newMessageText,"loading":_vm.loadingMess},on:{"click":_vm.sendMessage}})],1)],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }