var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-recap-box",class:{ container: _vm.$route.name === 'play' }},[_c('b-modal',{attrs:{"active":_vm.isSettingsModalActive,"has-modal-card":""},on:{"update:active":function($event){_vm.isSettingsModalActive=$event}}},[_c('bg-settings',{attrs:{"game":_vm.currentGame}})],1),(_vm.$route.name === 'play')?_c('b-sidebar',{attrs:{"open":_vm.showMessages && !_vm.showNewTurnDetails && !_vm.showOnboarding,"can-cancel":[],"type":"is-light","right":_vm.isMessageRight}},[_c('game-messages',{attrs:{"is-open":_vm.showMessages && !_vm.showNewTurnDetails && !_vm.showOnboarding}})],1):_vm._e(),_c('b-modal',{attrs:{"active":_vm.showOnboarding,"trap-focus":""},on:{"close":function($event){return _vm.$store.commit('setShowOnboarding', false)}}},[_c('bg-student-onboarding')],1),_c('nav',{staticClass:"columns game-info is-vcentered is-mobile is-multiline box"},[(_vm.isCreator)?_c('p',{staticClass:"column has-text-centered game-info"},[_c('b-button',{attrs:{"type":"is-warning","icon-left":"cog","size":"is-small"},on:{"click":function($event){_vm.isSettingsModalActive = true}}},[_vm._v(" Settings ")])],1):_vm._e(),(_vm.$route.name === 'play' && _vm.myFaci)?_c('p',{staticClass:"column has-text-centered game-info"},[_c('b-button',{attrs:{"type":"is-light","icon-left":"help-circle","size":"is-small"},on:{"click":function($event){return _vm.$store.commit('setShowOnboarding', true)}}},[_vm._v(" Instructions ")])],1):_vm._e(),(
        _vm.$route.name === 'play' &&
          (_vm.currentMessages.length ||
            (_vm.currentGame.params.configMessages &&
              _vm.currentGame.params.configMessages.allowChat))
      )?_c('p',{staticClass:"column has-text-centered game-info"},[_c('b-button',{staticClass:"btn-game-messages",attrs:{"type":"is-light","size":"is-small","icon-left":"email"},on:{"click":function($event){return _vm.$store.commit('setShowMessages', !_vm.showMessages)}}},[_vm._v(" Messages ")])],1):_vm._e(),(_vm.$route.name === 'play' && _vm.isCreator)?_c('p',{staticClass:"column has-text-centered game-info"},[_c('b-field',[_c('b-select',{attrs:{"placeholder":"Duration","size":"is-small"},model:{value:(_vm.localTimer),callback:function ($$v) {_vm.localTimer=$$v},expression:"localTimer"}},_vm._l((_vm.timerList),function(option){return _c('option',{key:option.duration,domProps:{"value":option.duration}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),_c('p',{staticClass:"control"},[_c('b-button',{staticClass:"btn-game-autoplay",attrs:{"type":"is-info is-light","icon-left":_vm.currentGame.params.playTimer
                ? 'timer-off-outline'
                : 'timer-outline',"size":"is-small"},on:{"click":_vm.toggleAutoPlay}},[_vm._v(" "+_vm._s(_vm.currentGame.params.playTimer ? "Stop" : "Start")+" Timer ")])],1)],1)],1):_vm._e(),(!_vm.currentGame.finished)?_c('p',{staticClass:"column has-text-centered game-info"},[_c('b-tooltip',{attrs:{"label":_vm.connectedUsersTooltip,"active":_vm.connectedUsersTooltip ? true : false,"type":"is-dark","position":"is-bottom","size":"is-small","multilined":""}},[_c('p',[_c('b-icon',{attrs:{"icon":"account","size":"is-small"}}),_c('span',{staticClass:"nowrap"},[_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("Users")]),_vm._v(" "+_vm._s(_vm.connectedUsers.length)+"/"+_vm._s(_vm.currentFacilities.length)+" ")])],1)])],1):_vm._e(),(_vm.$route.name === 'play' && !_vm.currentGame.finished)?_c('p',{staticClass:"column has-text-centered game-info"},[_c('b-tooltip',{attrs:{"label":_vm.waitingForTooltip,"type":"is-dark","position":"is-bottom","active":_vm.waitingForTooltip && _vm.ordersNotValidated.length > 0 ? true : false,"always":_vm.ordersNotValidated.length > 0 &&
            _vm.connectedUsers.length - _vm.ordersNotValidated.length > 0,"size":"is-small","multilined":""}},[_c('p',[_c('b-icon',{attrs:{"icon":"clipboard-text","size":"is-small"}}),_c('span',{staticClass:"nowrap"},[_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("Orders")]),_vm._v(" "+_vm._s(_vm.connectedUsers.length - _vm.ordersNotValidated.length)+"/"+_vm._s(_vm.connectedUsers.length)+" ")])],1)])],1):_vm._e(),_c('p',{staticClass:"column has-text-centered game-info",class:{
        'has-text-danger': _vm.currentGame.finished
      }},[_c('b-icon',{attrs:{"icon":"flag","size":"is-small"}}),(_vm.$route.name !== 'play')?_c('br'):_vm._e(),_c('span',{staticClass:"nowrap"},[_c('span',{staticClass:"is-hidden-mobile"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.currentGame.params.timeUnit || "week")))]),_vm._v(" "+_vm._s(_vm.currentGame.week)+" ")])],1),(
        _vm.$route.name === 'play' &&
          !_vm.currentGame.finished &&
          _vm.currentGame.params.playTimer
      )?_c('p',{staticClass:"column has-text-centered game-info"},[_c('timer-clock',{attrs:{"tooltip":"Time left to order","success":_vm.myFaci && _vm.myFaci.validatedOrder,"active":_vm.currentGame.params.playTimer,"timerNextTick":_vm.currentGame.params.timerNextTick,"show-alerts":_vm.myFaci ? true : false}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }