<template>
  <div class="faci-arrow-wrapper">
    <div
      class="svg-wrap-horizontal"
      :style="{
        top: isStage ? '50px' : onlyLeft ? '0px' : '-30px'
      }"
    >
      <div style="left:0;" class="is-absolute has-text-centered">
        <span
          v-if="!onlyLeft"
          class="is-size-6 mb-minus10"
          :class="{
            'has-text-danger': !isGrey,
            'has-text-grey-light': isGrey
          }"
        >
          <b-icon icon="email" size="is-small"></b-icon>
          <span v-if="faci && showLeadtime">
            {{ faci.leadtime > 1 ? 1 : 0.5
            }}{{ currentGame.params.timeUnit[0] }}</span
          >
        </span>
        <svg
          v-if="!onlyLeft"
          width="100%"
          height="15px"
          style="display:block;"
          class="mb-1"
        >
          <svg viewBox="0 0 1 2" preserveAspectRatio="xMaxYMid">
            <polyline
              :fill="isGrey ? 'none' : custoLook.bodyBckColor"
              stroke-width="0.1"
              :stroke="isGrey ? 'grey' : '#eb5757'"
              points="0,0 1,1 0,2 "
            />
          </svg>
          <svg viewBox="0 0 1 1" preserveAspectRatio="none">
            <rect
              x="0"
              y="0.475"
              width="1"
              height="0.05"
              :fill="isGrey ? 'grey' : '#eb5757'"
            />
          </svg>
        </svg>
        <svg
          width="100%"
          :height="onlyLeft ? '20px' : '15px'"
          style="display:block;"
          :style="onlyLeft ? 'min-width: 20px' : ''"
        >
          <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
            <polyline
              :fill="isGrey ? 'none' : custoLook.bodyBckColor"
              stroke-width="0.1"
              :stroke="isGrey ? 'grey' : '#0086e3'"
              points="1,0 0,1 1,2 "
            />
          </svg>
          <svg viewBox="0 0 1 1" preserveAspectRatio="none">
            <rect
              x="0"
              y="0.475"
              width="1"
              height="0.05"
              :fill="isGrey ? 'grey' : '#0086e3'"
            />
          </svg>
        </svg>
        <span
          v-if="!onlyLeft"
          class="is-size-6 mt-minus10 is-italic"
          :class="{
            'has-text-info': !isGrey,
            'has-text-grey-light': isGrey
          }"
        >
          <b-icon
            icon="truck"
            size="is-small"
            custom-class="mdi-flip-h"
          ></b-icon>
          <span v-if="faci && showLeadtime">
            {{ faci.leadtime > 1 ? faci.leadtime - 1 : 0.5
            }}{{ currentGame.params.timeUnit[0] }}</span
          >
          <!-- </b-tooltip> -->
        </span>
      </div>
      <!-- <div
        class="is-absolute has-text-centered"
        style="left:0; right:0; top: 28px; bottom: 28px;"
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <line
            x1="5"
            y1="0"
            x2="5"
            y2="10"
            stroke="grey"
            stroke-width="1"
            vector-effect="non-scaling-stroke"
            fill="none"
          />
        </svg>
      </div> -->
      <!-- ON THE RIGHT SIDE -->
      <!-- <div style="left:0; top:20px;" class="is-absolute has-text-centered">
        <svg width="100%" height="16px">
          <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
            <polyline
              :fill="custoLook.bodyBckColor"
              stroke-width="0.1"
              stroke="grey"
              points="1,0 0,1 1,2 "
            />
          </svg>
          <svg viewBox="0 0 1 1" preserveAspectRatio="none">
            <rect x="0.5" y="0.475" width="0.5" height="0.05" fill="grey" />
          </svg>
        </svg>
      </div> -->
    </div>
    <div class="svg-wrap-vertical has-text-centered">
      <svg width="20px" height="100%" overflow:visible>
        <svg viewBox="0 0 2 2" preserveAspectRatio="xMidYMin">
          <polyline
            stroke-width="0.1"
            stroke="grey"
            points="0,1 1,0 2,1 "
            fill="none"
          />
        </svg>
        <svg viewBox="0 0 1 1" preserveAspectRatio="none">
          <rect x="0.475" y="0" width="0.05" height="1" fill="grey" />
        </svg>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FaciArrow",
  props: {
    onlyLeft: Boolean,
    isStage: Boolean,
    isGrey: Boolean,
    faci: Object,
    showLeadtime: Boolean
  },
  computed: {
    ...mapGetters(["custoLook", "currentGame"]),
    tooltipText() {
      if (!this.faci) {
        return "";
      }
      let supName = this.faci.supplier_id
        ? this.currentGame.facilities[this.faci.supplier_id].name
        : null;
      return `Lead time ${supName ? "from " + supName : ""} to ${
        this.faci.name
      }: ${this.faci.leadtime} ${this.currentGame.params.timeUnit}s`;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.faci-arrow-wrapper {
  position: relative;
  height: 100%;
  padding: 0 5px;
  .is-absolute {
    position: absolute;
  }
  .svg-wrap-vertical {
    display: none;
  }
  @media (max-width: 1024px) {
    height: 30px;
    padding: 5px 0;
    .svg-wrap-horizontal {
      display: none;
    }
    .svg-wrap-vertical {
      display: block;
      height: 20px;
    }
  }
  .svg-wrap-horizontal {
    position: relative;
    height: 100%;
    // top: 50%;
    .mb-minus10 {
      margin-bottom: -0px;
    }
    .mt-minus10 {
      margin-top: -0px;
    }
    .is-size-7 {
      display: block;
    }
  }
  svg {
    // display: absolute;
    // margin-top: 50%;
    // width: 20px;
    line {
      stroke: rgb(133, 133, 133);
    }
  }
}
</style>
