var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faci-arrow-wrapper"},[_c('div',{staticClass:"svg-wrap-horizontal",style:({
      top: _vm.isStage ? '50px' : _vm.onlyLeft ? '0px' : '-30px'
    })},[_c('div',{staticClass:"is-absolute has-text-centered",staticStyle:{"left":"0"}},[(!_vm.onlyLeft)?_c('span',{staticClass:"is-size-6 mb-minus10",class:{
          'has-text-danger': !_vm.isGrey,
          'has-text-grey-light': _vm.isGrey
        }},[_c('b-icon',{attrs:{"icon":"email","size":"is-small"}}),(_vm.faci && _vm.showLeadtime)?_c('span',[_vm._v(" "+_vm._s(_vm.faci.leadtime > 1 ? 1 : 0.5)+_vm._s(_vm.currentGame.params.timeUnit[0]))]):_vm._e()],1):_vm._e(),(!_vm.onlyLeft)?_c('svg',{staticClass:"mb-1",staticStyle:{"display":"block"},attrs:{"width":"100%","height":"15px"}},[_c('svg',{attrs:{"viewBox":"0 0 1 2","preserveAspectRatio":"xMaxYMid"}},[_c('polyline',{attrs:{"fill":_vm.isGrey ? 'none' : _vm.custoLook.bodyBckColor,"stroke-width":"0.1","stroke":_vm.isGrey ? 'grey' : '#eb5757',"points":"0,0 1,1 0,2 "}})]),_c('svg',{attrs:{"viewBox":"0 0 1 1","preserveAspectRatio":"none"}},[_c('rect',{attrs:{"x":"0","y":"0.475","width":"1","height":"0.05","fill":_vm.isGrey ? 'grey' : '#eb5757'}})])]):_vm._e(),_c('svg',{staticStyle:{"display":"block"},style:(_vm.onlyLeft ? 'min-width: 20px' : ''),attrs:{"width":"100%","height":_vm.onlyLeft ? '20px' : '15px'}},[_c('svg',{attrs:{"viewBox":"0 0 2 2","preserveAspectRatio":"xMinYMid"}},[_c('polyline',{attrs:{"fill":_vm.isGrey ? 'none' : _vm.custoLook.bodyBckColor,"stroke-width":"0.1","stroke":_vm.isGrey ? 'grey' : '#0086e3',"points":"1,0 0,1 1,2 "}})]),_c('svg',{attrs:{"viewBox":"0 0 1 1","preserveAspectRatio":"none"}},[_c('rect',{attrs:{"x":"0","y":"0.475","width":"1","height":"0.05","fill":_vm.isGrey ? 'grey' : '#0086e3'}})])]),(!_vm.onlyLeft)?_c('span',{staticClass:"is-size-6 mt-minus10 is-italic",class:{
          'has-text-info': !_vm.isGrey,
          'has-text-grey-light': _vm.isGrey
        }},[_c('b-icon',{attrs:{"icon":"truck","size":"is-small","custom-class":"mdi-flip-h"}}),(_vm.faci && _vm.showLeadtime)?_c('span',[_vm._v(" "+_vm._s(_vm.faci.leadtime > 1 ? _vm.faci.leadtime - 1 : 0.5)+_vm._s(_vm.currentGame.params.timeUnit[0]))]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"svg-wrap-vertical has-text-centered"},[_c('svg',{attrs:{"width":"20px","height":"100%","overflow:visible":""}},[_c('svg',{attrs:{"viewBox":"0 0 2 2","preserveAspectRatio":"xMidYMin"}},[_c('polyline',{attrs:{"stroke-width":"0.1","stroke":"grey","points":"0,1 1,0 2,1 ","fill":"none"}})]),_c('svg',{attrs:{"viewBox":"0 0 1 1","preserveAspectRatio":"none"}},[_c('rect',{attrs:{"x":"0.475","y":"0","width":"0.05","height":"1","fill":"grey"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }