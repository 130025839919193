<template>
  <div
    class="game-page"
    :style="{
      'background-color': custoLook.bodyBckColor
    }"
    v-if="currentGame"
  >
    <section class="section">
      <div class="container">
        <div class="columns mb-0">
          <div class="column">
            <h1 class="title">
              {{ currentGame.name | capitalize({ onlyFirstLetter: true }) }}
            </h1>
            <h1 class="subtitle">
              {{ creationDate }}
              <span v-if="currentGame.finished" class="ml-3 has-text-danger">
                <b-icon icon="check-circle" size="is-small"></b-icon
                ><span>
                  Finished.
                </span>
              </span>
            </h1>
          </div>
          <div class="column is-narrow">
            <component
              v-if="currentGame && boxes[currentGame.type]"
              v-bind:is="boxes[currentGame.type]"
            ></component>
          </div>
        </div>
        <div class="bg-intro">
          <article class="intro-section">
            <section class="mb-5">
              <p>
                In this game, you play as one of the
                {{ currentFacilities.length }}
                <span v-if="currentGame.type === 'beergame'"
                  >industrial partners of a
                  {{ currentGame.params.product || "beer" }}
                  distribution network.</span
                >
                <span v-if="currentGame.type === 'leangame'"
                  >stations of a workshop.</span
                >
              </p>
              <p>The end consumer is played automatically by the computer.</p>
            </section>
            <section
              v-if="currentGame.type === 'beergame'"
              class="wrap-img columns is-hidden-mobile is-centered is-size-5 mt-2 mb-0"
            >
              <div class="column is-four-fifths columns is-vcentered">
                <div
                  class="column has-text-centered is-narrow is-paddingless has-text-grey-light"
                >
                  <b-icon size="is-large" icon="account"></b-icon>
                  <br />
                  Consumer
                </div>
                <div
                  class="column has-text-centered columns is-mobile is-vcentered"
                  :key="faciRank.id"
                  v-for="faciRank in currentFacilities"
                >
                  <div class="column is-paddingless is-3">
                    <svg class="svg-wrap-horizontal" width="100%" height="20px">
                      <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
                        <polyline
                          fill="#FAFAFA"
                          stroke-width="0.1"
                          stroke="grey"
                          points="1,0 0,1 1,2 "
                        />
                      </svg>
                      <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                        <rect
                          x="0"
                          y="0.475"
                          width="1"
                          height="0.05"
                          fill="grey"
                        />
                      </svg>
                    </svg>
                  </div>
                  <div class="column is-paddingless">
                    <b-icon size="is-large" :icon="faciRank.icon"></b-icon>
                    <br />
                    {{ faciRank.name | capitalize({ onlyFirstLetter: true }) }}
                  </div>
                </div>
              </div>
            </section>

            <b-notification
              :active="showError"
              @close="closeError"
              type="is-danger"
              >{{ showError ? error.message : "" }}</b-notification
            >
            <div
              v-if="currentGame.finished"
              class="has-text-centered my-6 pb-6"
            >
              <p class="is-size-4">
                This game is finished.
              </p>
              <p class="mt-4">
                <b-button
                  size="is-large"
                  type="is-success"
                  rounded
                  :style="{
                    'background-color': custoLook.btnBestColor,
                    color: 'white'
                  }"
                  tag="router-link"
                  :to="'/game/' + currentGame.id + '/results'"
                  icon-left="chart-line"
                >
                  See the results
                </b-button>
              </p>
            </div>
            <div v-if="!currentGame.finished">
              <h1
                class="title is-4 mt-2"
                v-if="!currentUser || !currentUser.playingGame"
              >
                Play as..
              </h1>
              <div
                v-if="
                  currentUser &&
                    currentUser.playingGame &&
                    currentUser.playingGame.id === $route.params.id
                "
              >
                <p class="is-size-5">
                  You are currently playing as
                  <strong>{{ currentUser.playingGame.roleName }}</strong> :
                </p>
                <div class="has-text-centered mt-1">
                  <b-button
                    size="is-medium"
                    type="is-success"
                    rounded
                    :style="{
                      'background-color': custoLook.btnBestColor,
                      color: 'white'
                    }"
                    tag="router-link"
                    :to="'/game/' + currentUser.playingGame.id + '/play'"
                    icon-right="arrow-right"
                  >
                    Back to the game stage
                  </b-button>
                </div>
                <p class="is-size-5 mt-4" v-if="planStatus.canJoin">
                  To play another role, select it below:
                </p>
              </div>
              <div
                v-if="
                  currentUser &&
                    currentUser.playingGame &&
                    currentUser.playingGame.id !== $route.params.id
                "
              >
                <p class="is-size-5">
                  You are already connected to another game :
                  <router-link
                    class="has-text-info has-text-weight-bold"
                    :to="'/game/' + currentUser.playingGame.id"
                  >
                    {{ currentUser.playingGame.name }}
                  </router-link>
                </p>
                <p class="is-size-5 mt-2" v-if="planStatus.canJoin">
                  To play this game, select one of the roles below:
                </p>
              </div>

              <div class="columns is-centered is-multiline mt-2">
                <div
                  class="column is-12 has-text-centered columns is-centered mb-0"
                  v-if="planStatus.message && planStatus.message.length"
                >
                  <div
                    class="column is-8 py-0"
                    :class="{
                      [`${
                        planStatus.messageClass
                          ? planStatus.messageClass
                          : 'has-text-danger'
                      }`]: true
                    }"
                  >
                    <b>{{ planStatus.message }}</b>
                  </div>
                </div>
                <div
                  class="column is-narrow has-text-centered"
                  v-for="faci in currentFacilities"
                  v-bind:key="faci.id"
                >
                  <div class="px-2">
                    <b-tooltip
                      :label="
                        currentUser && faci.user === currentUser.id
                          ? 'You are already playing this role'
                          : faci.user
                          ? 'This role is already being played by someone else'
                          : ''
                      "
                      :active="faci.user ? true : false"
                      type="is-dark"
                      position="is-top"
                      multilined
                    >
                      <b-button
                        class="btn-game-join my-1"
                        rounded
                        size="is-medium"
                        :type="'is-black'"
                        :icon-left="faci.icon"
                        :disabled="faci.user || !planStatus.canJoin"
                        :style="{
                          'background-color': custoLook.btnBestColor,
                          color: 'white'
                        }"
                        @click="joinAs(faci)"
                      >
                        {{ faci.name | capitalize({ onlyFirstLetter: true }) }}
                        <p v-if="faci.user" class="is-size-7">
                          {{ faci.playerName }}
                        </p>
                      </b-button>
                    </b-tooltip>

                    <p v-if="isCreator && faci.user">
                      <a
                        @click="disconnectUser(faci)"
                        class="has-text-danger has-text-weight-bold"
                      >
                        <b-icon icon="close-circle" size="is-small"></b-icon>
                        Disconnect user</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="has-text-centered my-3 has-text-weight-bold">
                  OR :
                </div>
                <div class="buttons is-centered">
                  <b-button
                    rounded
                    class="btn-game-join"
                    icon-left="dice-3"
                    type="is-info"
                    :disabled="!roleAvailable || !planStatus.canJoin"
                    @click="joinRandomFaci()"
                  >
                    Join a Random Position
                  </b-button>
                  <b-button
                    v-if="!myFaci"
                    rounded
                    class="button is-primary"
                    tag="router-link"
                    icon-left="telescope"
                    type="is-warning"
                    :to="'/game/' + currentGame.id + '/play'"
                  >
                    Access the stage as observer
                  </b-button>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BgRecapBox from "@/components/bg/BgRecapBox.vue";
import LeanRecapBox from "@/components/lean/LeanRecapBox.vue";

export default {
  name: "Game",
  computed: {
    ...mapGetters([
      "currentGame",
      "currentFacilities",
      "currentUser",
      "authUser",
      "error",
      "showError",
      "loading",
      "custoLook",
      "isCreator",
      "myFaci",
      "currentWorkspace"
    ]),
    roleAvailable() {
      return this.currentFacilities.some(faci => {
        return !faci.user;
      });
    },
    planStatus() {
      let obj = {
        canJoin: true,
        message: "",
        messageClass: this.isCreator ? "has-text-dark" : "has-text-danger"
      };
      if (
        this.currentWorkspace &&
        (!this.currentWorkspace.games ||
          !this.currentWorkspace.games.includes(this.currentGame.type))
      ) {
        obj.canJoin = this.isCreator;
        obj.message = this.isCreator
          ? "With a Free account, this game can only be played by you. It is a good way to discover how the app works! To organize multi-player sessions you'll need to upgrade your plan."
          : "The instructor doesn't have an active license for this game";
      } else if (
        this.currentWorkspace &&
        this.currentWorkspace.maxPlayersReached &&
        this.currentWorkspace.maxPlayersReached.includes(
          this.currentGame.type
        ) &&
        (!this.currentUser ||
          !this.currentUser.playingGame ||
          this.currentUser.playingGame.id !== this.currentGame.id)
      ) {
        obj.canJoin = this.isCreator;
        obj.message = this.isCreator
          ? "The maximum number of simultaneous players has been reached, additional participants won't be able to connect. Get more room by disconnecting players from previous games."
          : "The maximum number of players has been reached";
      }
      return obj;
    },
    creationDate() {
      let date = this.currentGame.created_at
        ? new Date(this.currentGame.created_at.seconds * 1000)
        : new Date();
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    }
  },
  mounted() {
    if (this.$route.query.join) {
      if (this.$route.query.join === "random") {
        this.joinRandomFaci();
      } else {
        this.$buefy.toast.open({
          duration: 1500,
          message: `Joining game as ${
            this.currentGame.facilities[this.$route.query.join].name
          }...`
        });
        this.joinAs({
          id: this.$route.query.join,
          name: this.currentGame.facilities[this.$route.query.join].name
        });
      }
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    quitGame: function() {
      if (this.authUser.email) {
        this.$store.dispatch("quitGame", this.currentUser.playingGame);
      } else {
        this.$store.dispatch("logout");
      }
    },
    disconnectUser(faci) {
      if (this.isCreator) {
        this.$store.dispatch("quitGame", {
          roleId: faci.id,
          id: this.currentGame.id,
          user_id: faci.user
        });
      }
    },
    joinAs(faci) {
      let gameObj = {
        id: this.$route.params.id,
        name: this.currentGame.name,
        roleId: faci.id,
        roleName: faci.name
      };
      this.$store
        .dispatch("joinGame", gameObj)
        .then(() => {
          setTimeout(() => {
            this.$router.push("/game/" + gameObj.id + "/play");
          }, 100);
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit("setError", {
            message: error.message
          });
        });
    },
    joinRandomFaci() {
      let availPos = this.currentFacilities.filter(faci => {
        return !faci.user;
      });
      let choosePos = availPos[Math.floor(Math.random() * availPos.length)];
      this.$store
        .dispatch("joinGame", {
          id: this.currentGame.id,
          name: this.currentGame.name,
          roleId: choosePos.id,
          roleName: choosePos.name
        })
        .then(payload => {
          this.connectTrials = 0;
          this.$buefy.dialog.alert({
            size: "is-medium",
            message: `You are joining as <b>${payload.roleName}</b>`,
            confirmText: `Let's go !`,
            type: "is-info",
            onConfirm: () => {
              this.$router.push("/game/" + payload.id + "/play");
            }
          });
        })
        .catch(() => {
          if (this.connectTrials < 30) {
            if (this.connectTrials === 0) {
              this.$buefy.toast.open({
                duration: 5000,
                queue: false,
                message: `Looking for an available position, this shouldn't take long...`
              });
            }
            this.connectTrials += 1;
            this.joinRandomFaci();
          } else {
            this.connectTrials = 0;
            this.$store.commit("setLoading", false);
            if (this.authUser && !this.authUser.email) {
              this.$store.dispatch("logout");
            }
            this.$store.commit("setError", {
              message:
                "Couldn't connect to a game. Try again or select manually one of the positions available below."
            });
          }
        });
    }
  },
  data() {
    return {
      connectTrials: 0,
      boxes: {
        leangame: LeanRecapBox,
        beergame: BgRecapBox,
        bgadvanced: BgRecapBox,
        bgpremium: BgRecapBox
      }
    };
  }
};
</script>

<style lang="scss">
.game-page {
  .section {
    .title .button {
      margin-left: 1em;
      padding: 6px;
    }
    .subtitle {
      margin-bottom: 0.5em;
    }
  }
}
</style>
