<template>
  <div class="lean-recap-box" :class="{ container: $route.name === 'play' }">
    <b-modal :active.sync="isSettingsModalActive" has-modal-card>
      <lean-settings :game="currentGame"></lean-settings>
    </b-modal>
    <nav class="columns game-info is-vcentered is-mobile is-multiline box">
      <p class="column has-text-centered game-info" v-if="isCreator">
        <b-button
          type="is-warning"
          icon-left="cog"
          @click="isSettingsModalActive = true"
          size="is-small"
        >
          Settings
        </b-button>
      </p>
      <p
        class="column has-text-centered game-info"
        v-if="$route.name === 'play' && myFaci"
      >
        <b-button
          type="is-light"
          icon-left="help-circle"
          size="is-small"
          @click="$store.commit('setShowOnboarding', true)"
        >
          Instructions
        </b-button>
      </p>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeanSettings from "@/components/lean/Play/LeanSettings.vue";

export default {
  name: "GameRecapBox",
  components: {
    LeanSettings
  },
  computed: {
    ...mapGetters([
      "currentFacilities",
      "currentGame",
      "currentUser",
      "isCreator",
      "showOnboarding",
      "showMessages",
      "currentMessages",
      "showNewTurnDetails",
      "portalLook",
      "myFaci"
    ])
  },
  methods: {},
  data() {
    return {
      isSettingsModalActive: false
    };
  }
};
</script>

<style lang="scss">
.lean-recap-box {
  &.container {
    padding: 0.8em;
    padding-bottom: 0;
  }
  .columns.game-info {
    margin: 0;
    padding: 0.4em;
  }
  .column.game-info {
    margin: 0 0.5em;
    padding: 0.3em;
    .b-tooltip {
      cursor: help;
    }
    .button {
      .icon {
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 2px;
      }
    }
    .icon {
      padding-right: 10px;
    }
  }
}
</style>
